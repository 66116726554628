import React from 'react';
import { Redirect } from '@reach/router';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import actions from '@store/actions';

import Page from '@components/layout/Page';
import usePagesQuery from '@graphql/queries/SimplePagesQuery';
import { PageContext } from '@content/types/pageContext';
import SimplePageComponent from '@components/simplePage/SimplePageComponent';
import DataLayer from '@components/layout/DataLayer';
import MetaPage from '@components/common/MetaPage';
import { LocationDependent } from '@content/types/general';
import { ConfiguratorActions } from '@store/actions';
import { ConfiguratorStateWithSelectors } from '@store/configurator/types';
import { RootState } from '@store/types';

const mapStateToProps = (state: RootState) => ({
  configurator: state.configurator,
  content: state.content,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  configuratorActions: bindActionCreators(actions.configurator, dispatch),
  contentActions: bindActionCreators(actions.content, dispatch),
});

interface BranchesPageProps extends LocationDependent {
  pageContext: PageContext;
  configuratorActions: typeof ConfiguratorActions;
  configurator: ConfiguratorStateWithSelectors;
}

const SimplePageTemplate: React.FC<BranchesPageProps> = (props: BranchesPageProps) => {
  const pages = usePagesQuery();
  const page = pages.allPages[props.pageContext.langcode].find(
    b => b.slug === props.pageContext.slug
  );

  return page ? (
    <Page>
      <MetaPage title={page.seo.title} description="" image={page.seo.image} />
      <DataLayer location={props.location} />
      <SimplePageComponent
        {...page}
        configuratorActions={props.configuratorActions}
        configurator={props.configurator}
      />
    </Page>
  ) : (
    <Redirect to="/404" />
  );
};

const SimplePageTemplateConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(SimplePageTemplate);

export default SimplePageTemplateConnected;
