import React, { Component } from 'react';

// === Helpers === //
import styled from '@emotion/styled';
import * as utils from '@utils';
import * as Yup from 'yup';

// === Config === //
import { ConfiguratorActions } from '@store/actions';
import { ConfiguratorStateWithSelectors } from '@store/configurator/types';
import { respondFrom, breakpoints, css } from '@styles';
import {
  SimplePageFormButton,
  SimplePageFormAgreement,
  SimplePageFormFields,
  SimplePageFormModal,
} from '@content/types/simplePage';

// === Components === //
import { Formik, FormikActions, Form } from 'formik';
import { DemoFormInput } from '@components/demo/DemoFormInput';
import { DemoFormCheckbox } from '@components/demo/DemoFormCheckbox';
import Button from '@components/common/Button';
import Tooltip from '@components/common/Tooltip';
import Modal from '@components/common/Modal';
import LoaderCover from '@components/common/LoaderCover';

const SimplePageFormWrapper = styled.div`
  margin-bottom: 120px;
  margin-top: 80px;

  h2 {
    text-align: center;
    margin-bottom: 50px;
  }
`;

const SimplePageFormInner = styled.div`
  ${respondFrom(
    breakpoints.lg,
    css`
      max-width: 420px;
      margin: auto;
    `
  )}
`;

const ButtonWrapper = styled.div`
  display: inline-block;
`;

export interface SimpleFormFormValues {
  name: string;
  email: string;
  phone: string;
  agreement: boolean;
}

interface SimplePageFormProps {
  configuratorActions: typeof ConfiguratorActions;
  configurator: ConfiguratorStateWithSelectors;
  title: string;
  formFields: SimplePageFormFields[];
  agreement: SimplePageFormAgreement;
  button: SimplePageFormButton;
  id: string;
  modal: SimplePageFormModal;
}

class SimplePageForm extends Component<SimplePageFormProps> {
  state = {
    agreement: false,
    error: false,
    success: false,
  };

  onChangeCheckbox = value => this.setState({ agreement: !value });

  render() {
    const {
      title,
      formFields,
      agreement,
      button,
      id,
      modal,
      configuratorActions,
      configurator,
    } = this.props;
    const validationSchema = Yup.object().shape({
      name: Yup.string().required(
        formFields[0].validation ? formFields[0].validation.requiredMessage : ''
      ),
      phone: Yup.string()
        .matches(/^[0-9]{9,}$/, {
          message: formFields[1].validation ? formFields[1].validation.phoneMessage : '',
        })
        .required(formFields[1].validation ? formFields[1].validation.requiredMessage : ''),
      email: Yup.string()
        .email(formFields[2].validation ? formFields[2].validation.emailMessage : '')
        .required(formFields[2].validation ? formFields[2].validation.requiredMessage : ''),
    });

    return (
      <SimplePageFormWrapper id="simple-form">
        <h2>{utils.SafeHtml(title)}</h2>

        <SimplePageFormInner>
          <Formik
            initialValues={{
              name: '',
              email: '',
              phone: '',
              agreement: false,
            }}
            validationSchema={validationSchema}
            onSubmit={(
              values: SimpleFormFormValues,
              actions: FormikActions<SimpleFormFormValues>
            ) => {
              configuratorActions.setLoading(true);

              this.setState({ error: false, success: false }, () => {
                fetch(`${process.env.IPOS_BE_API_URL}/contact/simple_form`, {
                  method: 'POST',
                  body: JSON.stringify({
                    title,
                    name: values.name,
                    phone: values.phone,
                    email: values.email,
                    agreement: this.state.agreement,
                  }),
                  headers: {
                    'Content-Type': 'application/json',
                  },
                })
                  .then(response => {
                    configuratorActions.setLoading(false);

                    if (response.status === 204) {
                      this.setState({ success: true, agreement: false }, () => {
                        actions.resetForm();
                      });
                    } else {
                      this.setState({ error: true });
                    }
                  })
                  .catch(error => {
                    console.error(error);
                    this.setState({ error: true });
                  });
              });
              actions.setSubmitting(false);
            }}
            render={() => (
              <Form autoComplete="on">
                {formFields.map((field: SimplePageFormFields) => (
                  <DemoFormInput
                    key={field.name}
                    name={field.name}
                    labelText={field.label}
                    type={field.type}
                    maxlength={field.name === 'phone' ? 9 : 100}
                  />
                ))}
                <DemoFormCheckbox
                  name="agreement"
                  labelText={agreement.label}
                  checked={this.state.agreement}
                  fromState={true}
                  onChangeCheckbox={() => this.setState({ agreement: !this.state.agreement })}
                />
                <ButtonWrapper data-tip="React-tooltip" data-for={`simplePageButton-${id}`}>
                  <Button
                    icon="arrow-right"
                    type="submit"
                    disabled={!this.state.agreement ? true : false}
                  >
                    {button.label}
                  </Button>
                </ButtonWrapper>
              </Form>
            )}
          />

          <Modal
            isOpened={this.state.error || this.state.success}
            content={this.state.success ? modal.success : modal.error}
          />
          <Tooltip
            className={this.state.agreement ? 'is-hidden' : ''}
            text={button.tooltip}
            theme="light"
            id={`simplePageButton-${id}`}
          />
          <LoaderCover active={configurator.loading} />
        </SimplePageFormInner>
      </SimplePageFormWrapper>
    );
  }
}

export default SimplePageForm;
