/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import { ConfiguratorActions } from '@store/actions';
import { ConfiguratorStateWithSelectors } from '@store/configurator/types';

import Page from '@components/layout/Page';
import Container from '@components/layout/Container';
import BranchWhatIsIpos from '@components/branches/sections/BranchWhatIsIpos';
import BranchBullets from '@components/branches/sections/BranchBullets';
import BranchRecommendation from '@components/branches/sections/BranchRecommendation';
import BranchFeatures from '@components/branches/sections/BranchFeatures';
import BranchFeatures2 from '@components/branches/sections/BranchFeatures2';
import BranchDevices from '@components/branches/sections/BranchDevices';
import BranchTariffs from '@components/branches/sections/BranchTariffs';
import BranchTestimonial from '@components/branches/sections/BranchTestimonial';
import SimplePageForm from '@components/simplePage/SimplePageForm';
import PageHero from '@components/common/PageHero';

interface SimplePageComponentsProps {
  components: Array<any>;
  langcode: string;
  urls: Array<string>;
  slug: string;
  configuratorActions: typeof ConfiguratorActions;
  configurator: ConfiguratorStateWithSelectors;
}

const SimplePageComponents = ({
  components,
  langcode,
  urls,
  slug,
  configuratorActions,
  configurator,
}: SimplePageComponentsProps) => {
  return (
    <Page>
      {components.length > 0 &&
        components.map((comp, index) => {
          if (comp && comp.type === 'hero') {
            comp.buttonRoute = '#simple-form';
            comp.buttonOutside = true;
            return <PageHero key={index} settings={comp} langcode={langcode} urls={urls} />;
          } else if (comp && comp.type === 'what_is_ipos') {
            return <BranchWhatIsIpos {...comp} key={index} />;
          } else if (comp && comp.type === 'bullets') {
            return <BranchBullets {...comp} key={index} noLastDot={true} langcode={langcode} />;
          } else if (comp && comp.type === 'recommendation') {
            return (
              <BranchRecommendation
                {...comp}
                slug={slug}
                key={index}
                langcode={langcode}
                urls={urls}
              />
            );
          } else if (comp && comp.type === 'features') {
            return <BranchFeatures {...comp} key={index} />;
          } else if (comp && comp.type === 'features2') {
            return (
              <BranchFeatures2 {...comp} slug={slug} key={index} langcode={langcode} urls={urls} />
            );
          } else if (comp && comp.type === 'products') {
            return (
              <BranchDevices
                {...comp}
                key={index}
                branchSlug={slug}
                langcode={langcode}
                urls={urls}
              />
            );
          } else if (comp && comp.type === 'tariffs') {
            return (
              <BranchTariffs
                {...comp}
                key={index}
                branchSlug={slug}
                langcode={langcode}
                urls={urls}
              />
            );
          } else if (comp && comp.type === 'testimonial') {
            return <BranchTestimonial {...comp} key={index} />;
          } else if (comp && comp.type === 'simple-form') {
            return (
              <Container key={index}>
                <SimplePageForm
                  {...comp}
                  configuratorActions={configuratorActions}
                  configurator={configurator}
                />
              </Container>
            );
          }
        })}
    </Page>
  );
};

export default SimplePageComponents;
