import React from 'react';
import styled from '@emotion/styled';

import * as utils from '@utils';
import Page from '@components/layout/Page';
import Container from '@components/layout/Container';
import MetaPage from '@components/common/MetaPage';
import { SimplePageDataProps } from '@content/types/simplePage';
import SimplePageComponents from '@components/simplePage/SimplePageComponents';
import { ConfiguratorActions } from '@store/actions';
import { ConfiguratorStateWithSelectors } from '@store/configurator/types';

const ContentWrapper = styled.div`
  padding: 105px 0 75px;
  max-width: 850px;
  line-height: 1.43;
  margin: auto;
`;

interface Props extends SimplePageDataProps {
  langcode: string;
  allUrls: Array<string>;
  configuratorActions: typeof ConfiguratorActions;
  configurator: ConfiguratorStateWithSelectors;
}

export default (props: Props) => {
  return (
    <Page>
      <MetaPage title={props.title} image={props.seo.image} />
      <SimplePageComponents
        components={props.components}
        langcode={props.langcode}
        slug={props.slug}
        urls={props.allUrls}
        configuratorActions={props.configuratorActions}
        configurator={props.configurator}
      />

      {props.paragraph && props.paragraph !== '' && (
        <Container>
          <ContentWrapper>{utils.SafeHtml(props.paragraph)}</ContentWrapper>
        </Container>
      )}
    </Page>
  );
};
